import { Text, useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { IonPicker, type PickerColumn, type PickerColumnOption } from "@ionic/react";
import { Button } from "@mui/material";
import {
  addDays,
  addMonths,
  format,
  isAfter,
  isSameDay,
  isValid,
  parseISO,
  setHours,
  setMinutes,
} from "date-fns";
import { useCallback, useMemo, useState } from "react";

interface Props {
  onConfirm: (value: Date) => void;
  minuteStep?: number;
  minDateTime?: Date;
  maxDateTime?: Date;
  disabled?: boolean;
  initialValue?: Date;
}

export function DateTimePicker(props: Props) {
  const {
    onConfirm,
    disabled = false,
    minuteStep = 15,
    minDateTime = new Date(),
    maxDateTime = addMonths(minDateTime, 1),
    initialValue,
  } = props;

  if (minuteStep <= 0) {
    throw new Error(`Minute step cannot be 0 or less, received: ${minuteStep}`);
  }

  if (!isAfter(maxDateTime, minDateTime)) {
    throw new Error(
      `Minimum date cannot be after maximum date, received minimum: ${minDateTime.toISOString()} and maximum: ${maxDateTime.toISOString()}`
    );
  }

  const minHourForMinDate = minDateTime.getHours();
  const minMinutesForMinHour = minDateTime.getMinutes();

  const maxHourForMaxDate = maxDateTime.getHours();
  const maxMinutesForMaxHour = maxDateTime.getMinutes();

  const pickerModalState = useModalState();
  const [selectedDateTime, setSelectedDateTime] = useState<Date | undefined>(initialValue);

  const [picker, setPicker] = useState<HTMLIonPickerElement>();
  const [selectedDateIndex, setSelectedDateIndex] = useState(0);
  const [selectedTimeIndex, setSelectedTimeIndex] = useState(0);

  const onPickerColumnChange = useCallback(
    (event: {
      detail: {
        name: string;
        options: PickerColumnOption[];
        prevSelected: number;
        selectedIndex: number;
      };
    }) => {
      if (event.detail.name === "dayOfMonth") {
        setSelectedDateIndex(event.detail.selectedIndex);
      }

      if (event.detail.name === "timeOfDay") {
        setSelectedTimeIndex(event.detail.selectedIndex);
      }
    },
    []
  );

  const dateOptions = useMemo<PickerColumnOption[]>(() => {
    const dateOptions: PickerColumnOption[] = [];
    // we want to skip showing dates that don't have any hours or minutes selectable
    const noHoursOrMinutesToShowOnMinDay =
      minDateTime.getHours() === 23 && minDateTime.getMinutes() > 60 - minuteStep;
    const minDateAdjusted = addDays(minDateTime, noHoursOrMinutesToShowOnMinDay ? 1 : 0);
    const noHoursOrMinutesToShowOnMaxDay =
      maxDateTime.getHours() === 0 && minDateTime.getMinutes() < minuteStep;
    const maxDateAdjusted = addDays(maxDateTime, noHoursOrMinutesToShowOnMaxDay ? 0 : 1);
    for (let day = minDateAdjusted; !isSameDay(day, maxDateAdjusted); day = addDays(day, 1)) {
      dateOptions.push({ value: day.toISOString(), text: format(day, "MMM d") });
    }

    // if the picker is already open, we need to replace all the columns to refresh them
    if (isDefined(picker)) {
      picker.columns[0].options = dateOptions;
      picker.columns = JSON.parse(JSON.stringify(picker.columns)) as PickerColumn[];
    }

    return dateOptions;
  }, [minDateTime, minuteStep, maxDateTime, picker]);

  // dynamically calculate the time options to display based on which date was selected
  const timeOptions = useMemo<PickerColumnOption[]>(() => {
    const timeOptions: PickerColumnOption[] = [];

    const selectedDate = parseISO(dateOptions[selectedDateIndex].value as string);
    const hasSelectedMinDate = isSameDay(selectedDate, minDateTime);
    const hasSelectedMaxDate = isSameDay(selectedDate, maxDateTime);
    // only limit the floor of hours if minimum date is selected
    const minimumHour = hasSelectedMinDate ? minHourForMinDate : 0;
    // only limit the ceiling of hours if maximum date is selected
    const maximumHour = hasSelectedMaxDate ? maxHourForMaxDate : 23;

    for (let hour = 0; hour <= 23; hour += 1) {
      // only limit the floor of minutes if minimum date and minimum hour are selected
      let minimumMinute =
        hasSelectedMinDate && hour === minHourForMinDate ? minMinutesForMinHour : 0;
      // round up to closest valid value for the minute step
      // e.g. with minute step 15: if the minimum minute is 27, we should show 30; if the minimum minute is 15, we should show 15
      minimumMinute = Math.ceil(minimumMinute / minuteStep) * minuteStep;

      // only limit the ceiling of minutes if maximum date and maximum hour are selected
      let maximumMinute =
        hasSelectedMaxDate && hour === maximumHour ? maxMinutesForMaxHour : 60 - minuteStep;
      // we want to skip showing minute 60, so we only go to a max of 59 minutes
      maximumMinute = Math.min(maximumMinute, 59);

      for (let minute = 0; minute <= 59; minute += minuteStep) {
        const dateToUseForFormattingTime = setHours(setMinutes(new Date(), minute), hour);

        // disable options that are outside the desired time range
        const disabled =
          hour < minimumHour ||
          hour > maximumHour ||
          minute < minimumMinute ||
          minute > maximumMinute;

        timeOptions.push({
          value: { hour, minute },
          text: format(dateToUseForFormattingTime, "h:mm a"),
          disabled,
        } as PickerColumnOption);
      }
    }

    // if the picker is already open, we need to replace all the columns to refresh them
    if (isDefined(picker)) {
      picker.columns[1].options = timeOptions;
      picker.columns = JSON.parse(JSON.stringify(picker.columns)) as PickerColumn[];
    }

    return timeOptions;
  }, [
    dateOptions,
    selectedDateIndex,
    minDateTime,
    minHourForMinDate,
    maxDateTime,
    maxHourForMaxDate,
    picker,
    minMinutesForMinHour,
    minuteStep,
    maxMinutesForMaxHour,
  ]);

  return (
    <>
      <Button
        fullWidth
        variant="outlined"
        disabled={disabled}
        sx={{ borderColor: (theme) => theme.palette.grey[500] }}
        aria-label="Select Time"
        onClick={pickerModalState.openModal}
      >
        <Text>
          {isDefined(selectedDateTime) && isValid(selectedDateTime)
            ? format(selectedDateTime, "MMM d, h:mm a")
            : "Tap here to select a time"}
        </Text>
      </Button>
      {/* <input type="date" /> */}
      <IonPicker
        buttons={[
          {
            text: "Cancel",
            handler: () => {
              pickerModalState.closeModal();
            },
          },
          {
            text: "Done",
            handler: (value: { dayOfMonth: PickerColumnOption; timeOfDay: PickerColumnOption }) => {
              let dateToSelect = parseISO((value.dayOfMonth.value as string).trim());
              const timeOfDay = value.timeOfDay.value as { hour: number; minute: number };
              dateToSelect = setHours(setMinutes(dateToSelect, timeOfDay.minute), timeOfDay.hour);
              setSelectedDateTime(dateToSelect);
              onConfirm(dateToSelect);
              pickerModalState.closeModal();
            },
          },
        ]}
        isOpen={pickerModalState.modalIsOpen}
        columns={[
          {
            name: "dayOfMonth",
            options: dateOptions,
            selectedIndex: selectedDateIndex,
          },
          {
            name: "timeOfDay",
            options: timeOptions,
            selectedIndex: selectedTimeIndex,
          },
        ]}
        onDidPresent={(event) => {
          const picker = event.currentTarget as HTMLIonPickerElement;
          setPicker(picker);
          // using any as ionic event listener has types only for basic HTML events
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          picker.addEventListener("ionPickerColChange", onPickerColumnChange as any);
        }}
        onDidDismiss={(event) => {
          setPicker(undefined);
          const picker = event.currentTarget as HTMLIonPickerElement;
          // using any as ionic event listener has types only for basic HTML events
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          picker.removeEventListener("ionPickerColChange", onPickerColumnChange as any);

          pickerModalState.closeModal();
        }}
      />
    </>
  );
}
